import * as React from "react";
import { useLocation } from "@reach/router";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import Footer from "../components/Footer/Footer";

import "./ProfilePage.css";

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider";

const ProfilePage = ({ pageContext }) => {
  const dispatch = React.useContext(GlobalDispatchContext);

  return (
    <Page>
      <TopBar showSearch={true} hideCurrency={true} />
      <div style={{ maxWidth: 1000, margin: "0 auto", minHeight: "60vh" }}>
        <Section>
          <SectionHeading>Max Maybury | AI Product Reviews</SectionHeading>
          <div style={{ maxWidth: 900 }}>
            <div className="profile-page">
              <div className="profile-page-photo">
                <img src="/images/max-maybury.png" />
              </div>
              <div className="profile-page-bio">
                <p>
                  Max is a young businessman and the developer of the AI Product
                  Reviews platform. His entrepreneurial spirit and desire to
                  know as much about AI tech as possible make Max the perfect
                  fit for this project. Partnering with Pete, Max intends to
                  uncover a world of AI platforms that can revolutionize the way
                  businesses are run and managed.
                </p>
                <p>
                  Technology and smart business solutions have always been
                  interests for Max, and now he is using that expertise and
                  enthusiasm to help us review AI tools and provide suggestions
                  for future tools to review. With his keen understanding of
                  technology and AI, Max intends to help foster an environment
                  on the AI Product Reviews site of transparent, honest,
                  technical reviews that help people to make informed decisions.
                </p>
              </div>
            </div>
          </div>
        </Section>
      </div>
      <Footer />
    </Page>
  );
};

export default ProfilePage;

export const Head = () => {
  const location = useLocation();
  return (
    <SEO
      index
      follow
      title={"Max Maybury | AI Product Reviews"}
      description={
        "Explore Max Maybury's contributions to AI product reviews, providing comprehensive and unbiased evaluations of AI technologies. "
      }
      pathname={location.pathname}
    />
  );
};
